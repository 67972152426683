<template>
	<ul class="editable-list">
        <li class="editable-list__item" v-for="d in devices" :key="d.id">
            <div class="data-row">
                <ul class="data-row__list">
                    <li class="data-row__item">
                        {{d.name}}
                    </li>
                    <li class="data-row__item">
                        <span class="data-row__icon">
                            <DeviceIcon :category="d.category"></DeviceIcon>
                        </span>
                        {{d.category}}
                    </li>
                    <li class="data-row__item">
                        {{d.location||"none"}}
                    </li>
                </ul>
                <div class="data-row__controls" v-if="deleteCircle">
                    <button type="button" class="btn btn-icon red-color" @click="deleteDevice(d.id)">
                        <inline-svg :src="require('@/assets/img/Delete-circle.svg')" />
                    </button>
                </div>
            </div>
        </li>
    </ul>

</template>




<script>

import Buttons from "@/components/buttons";
import Base from '@/components/base';
export default {
	name: "DeviceRows",
    props:['devices'],
	components: {
		
		...Buttons,
        ...Base
	},

	data() {
		return {
	
		}
	},
	computed: {
		deleteCircle(){
            if(this.$listeners.delete){
                return true
            }
            return false
        }   
	},
	methods: {
        deleteDevice(id){
            this.$emit('delete','devices',id)
        }
	}
};
</script>