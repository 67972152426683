<template>
<div class="wrapper_main footer-fixed">
		<main class="content">
		<div class="content__header sub-page">
			<div class="content__header-left">
				<router-link to="/device-groups" class="btn btn-w-shadow btn-sm btn-back">
                        <inline-svg :src="require('@/assets/img/Arrow-prev.svg')" />
                </router-link>
			
				<div class="heading-wrapper flex items-center" v-if="editName.show" >
					<button class="btn btn-transp btn-rename" @click="editNameSubmit" :class="{processing}">
						<inline-svg :src="require(`@/assets/img/checkmark-circle.svg`)" />
					</button>
					<input type="text" class="input head-controls__input" v-model="editName.name" />
				</div>
				<div class="heading-wrapper flex items-center" v-else>
					<button class="btn btn-transp btn-rename" @click="editNameOpen">
						<img src="@/assets/img/Pen-Edit.svg" />
					</button>
					<h1 class="heading-page-h1">{{ current.name }}</h1>
				</div>
			</div>
			<div class="content__header-right">
				<button type="button" class="btn btn-primary btn-standart" data-target="add-device" @click="addDeviceOpen">
					<SvgIcons icon="add-iot-icon.svg" />
					<span>{{ $translate('add-iot-device') }}</span>
				</button>
			</div>
		</div>

		<TableAdvanced v-model="sortCol" :rows="filteredRows" :columns="columns" :isCheckedAll="isCheckedAll" :checkedInputs="checkedInputs" @toggleAllCheckedInputs="toggleAllCheckedInputs">
                <template v-slot:filters>
					<div class="filter-options__search">
						<input type="search" class="input bg-left search-icon" placeholder="Search" v-model="rowsFilters.search" />
					</div>
					<div class="filter-options__items flex-x-wrap flex-auto">
						<div style="max-width: 350px; width: 100%;">
							<DropdownAdvanced
								placeholder="Categories"
								:options="deviceCategories"
								v-model="rowsFilters.categoryName"
							/>
						</div>
						<!-- <DropdownAdvanced
							selectAll="Locations"
							:options="locationOptions"
							v-model="rowsFilters.locationName"
						/> -->
						<div style="max-width: 350px; width: 100%;">
							<DropdownAdvanced
								placeholder="Status"
								:options="statusOptions"
								v-model="rowsFilters.status"
							/>
						</div>
						<div style="max-width: 350px; width: 100%;">
							<DropdownAdvanced
								placeholder="Type"
								:options="deviceTypes"
								v-model="rowsFilters.device_type"
							/>
						</div>
						<!-- <button type="button" class="btn btn-w-shadow btn-standart-2">
							<inline-svg :src="require(`@/assets/img/map-location-pin.svg`)"/>
							<span>{{ $translate('map') }}</span>
						</button> -->
					</div>
                </template>

                <template v-slot:row="slotData">
					<td>
						<label class="module__check">
							<input type="checkbox" name="device" :checked="checkedInputs[slotData.r.id]" @change="setCheckedInputs($event,slotData.r.id)"/>
							<span class="check"></span>
							<span class="text">{{slotData.r.name}}</span>
						</label>
					</td>
					<td>
						<div class="flex icon-text">
							<DeviceIcon :category="slotData.r.category"></DeviceIcon>
							<span>{{slotData.r.categoryName}}</span>
						</div>
					</td>
					<td>
						{{slotData.r.date_of_purchase}}
					</td>
					<td> 
						{{ slotData.r.device_type }} 
					</td>
					<td>
						<div v-if="slotData.r.locationName" class="flex icon-text">
							<inline-svg :src="require(`@/assets/img/Pin-Location.svg`)"/>
							<span>{{slotData.r.locationName}}</span>
						</div>
						<span v-else>-</span>
					</td>
					<td>{{ slotData.r.device_info||'-' }}</td>
					<td>
						<span class="table-statys enable"><span class="dot"></span>{{slotData.r.status}}</span>
					</td>
				

					<td class="position-stiky">
						<div class="table-actions flex items-center">
							<button type="button" class="btn btn-transp">
								<inline-svg :src="require(`@/assets/img/Document.svg`)"/>
								<span>{{ $translate('report') }}</span>
							</button>
							<router-link class="btn btn-transp" :to="'/devices/'+slotData.r.id">
								<inline-svg :src="require(`@/assets/img/ico-gear.svg`)"/>
							</router-link>
							<button type="button" class="btn btn-transp red-color" @click="confirmDelete(slotData.r.id, $translate('delete-device?'))">
								<inline-svg :src="require(`@/assets/img/trash-delete.svg`)"/>
							</button>
						</div>
					</td>

                </template>
            </TableAdvanced>
	</main>
	<Pagination v-model="filteredRows" :items="rawSource" />
	<ConfirmAction 
		v-if="deletePopupInfo.show" 
		:title="$translate('delete-device?')" 
		:message="$translate('do-you-confirm-deleting-this-device-from-group?')" 
		@close="confirmDeleteClose" 
		@confirm="confirmDeleteAction" 
		:processing="dProcessing"
	/>
	<!-- berfore translate "Delete Device?" message="Do you confirm deleting this device from group?" -->
	<AddDevice
		v-if="addDevicesPopupInfo.show"
		:devices="addDevicesPopupInfo.devices"
		@close="addDeviceClose"
		@submit="addDeviceSubmit"
		:title="$translate('assign-device-to-group')"
		:processing="aProcessing"
	/>
	<!-- before translate title=" Assign Device(s) to group" -->

</div>
</template>

<script>
import { mixTables,mixDeleteItem,mixArrayFields,mixProcessing } from "@/mixins";
import {
	updateDeviceGroup,
	updateDevice
	
} from '@/graphql/mutations';
import { API } from 'aws-amplify';
import Base from "@/components/base";
import Popups from "@/components/popups";
import Buttons from "@/components/buttons";
import ItemsRows from "@/components/itemsRows";
import { devCats,devTypes,devStatus } from '@/config';

export default {
	name: "DeviceGroupPage",
	components: {
		...Base,
		...Popups,
		...Buttons,
		...ItemsRows
	},
	mixins:[mixTables,mixDeleteItem,mixArrayFields,mixProcessing],
	props:['id'],
	data() {
		return {
			source: "groups",
			columns: [
				{ name: 'Device Name', filter: 'name' },
				{ name: 'Category', filter: 'categoryName' },
				{ name: 'Date of Purchase', filter: 'date_of_purchase' },
				{ name: 'Type', filter: 'device_type' },
				{ name: 'Location', filter: 'locationName' },
				{ name: 'Device Info', filter: 'device_info' },
				{ name: 'Status', filter: 'status' },
				{ name: 'Actions', class:'text-end'}
			],
			calculatedFields:[
				{
					filter: 'locationName',
					path: ['location'],
					calcFunction: (location)=>location.address||null
				},
				{
					filter: 'categoryName',
					path: [],
					calcFunction: (device)=>device.category=="custom_category"?device.custom_category:device.category 
				}
			],
			deleteQueryType:"updateDevice",
			deleteEntryName:"device_groupID",
			arrayFields:{
				devices:[]
			},

			editName:{
                show:false,
                name:null
            }

												
		}
	},
	computed: {
		devices() {
			return this.$store.state.devices
		},
		companyData(){
			return this.$store.state.companyData
		},
		groups(){
			return this.$store.state.groups
		},
		current() {
			let currentGroup=this.groups.find((g) => g.id === this.id) || {};
			if(currentGroup.devices){
				this.setFieldItems('devices',this.transformFieldToId(currentGroup.devices.items))
			}
			return currentGroup
		},
		sourceData(){
			// console.log("SOURCE DATA",this.currentGroups );
			// return this.current.devices ? this.current.devices.items : [];
			if(!this.current.devices) return
			let devicesID=this.current.devices.items.map(device=>device.id)
			return this.$store.state.devices.filter(device=>{
				return devicesID.includes(device.id)
			})
		},

		deviceCategories() {
			let categoriesMain=new Set();
			let categoriesCustom=new Set();
			this.$store.state.devices.forEach(d=>{
				if(d.category=='custom_category'){
					categoriesCustom.add(d.custom_category)
				}else{
					categoriesMain.add(d.category)
				}
			})
			console.log(categoriesMain,categoriesCustom);
			let categories=[]
			categoriesMain.forEach(deviceCategory=>{			
				categories.push(devCats.find(category=>category.id==deviceCategory))
			})
			categoriesCustom.forEach(deviceCategory=>{
				categories.push({id:deviceCategory,name:deviceCategory})
			})
            return categories
        },
		deviceTypes() {
			return devTypes
		},
		statusOptions() {
            return [
                ...Object.keys(devStatus)
                .filter(opt => opt !== 'true' && opt !== 'false')
                .map(o => ({ id: o, name: o }))
            ]
        },
		locationOptions(){
			return [
				
			]
		},
	
	},
	methods: {
		async addDeviceSubmit(devices){
			this.aProcessing=true
			const requests = devices.map((deviceID) =>
                API.graphql({
                    query: updateDevice,
                    variables: {
                        input: {
                            id: deviceID,
               				device_groupID: this.id
                        },
                    },
                }).then((res) => res.data.updateDevice)
            )
            await Promise.all(requests);
			await this.refreshData()
			this.aProcessing=false
			this.addDeviceClose()
        },

		editNameOpen(){
            this.editName.show=true
            this.editName.name=this.current.name
        }, 
        editNameClose(){
            this.editName.show=false
            this.editName.name=null
        }, 
        async editNameSubmit(){
            this.processing = true;

            const group = await API.graphql({
                query: updateDeviceGroup,
                variables: {
                    input: {
                        id: this.id,
                        name:this.editName.name,
                    },
                },
            }).then((res) => res.data.updateDeviceGroup);
            this.$store.commit('UPDATE_ITEM', { field: 'groups', item: group })
            this.editNameClose()
            this.processing = false;
          
        },
	}
};
</script>

<style scoped>
	.position-stiky{
		padding-top: 24px !important;
		padding-bottom: 24px !important;
	}
</style>